<script setup lang="ts">
  defineProps<{
    symbol: string
    size?: number | string
    title?: string | null
  }>()

  const attrs = useAttrs()
</script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
    :height="size ?? (attrs.height as number) ?? '100%'"
    :width="size ?? (attrs.width as number) ?? '100%'"
  >
    <title v-if="title?.length">{{ title }}</title>

    <use :xlink:href="`#${symbol}`" />
  </svg>
</template>
