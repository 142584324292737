<script setup lang="ts">
  import type { INotifyItem } from '../types/notify'

  const props = defineProps<{
    toast: INotifyItem
  }>()

  const { $notify } = useNuxtApp()
  const { t } = useI18n()

  const close = (): void => {
    $notify.remove(props.toast)
  }

  const title = computed<string | undefined>(() => {
    if (props.toast.title) {
      return props.toast.title
    }

    switch (props.toast.type) {
      case 'error':
        return t('status.title.error')

      case 'info':
        return t('status.title.info')

      case 'success':
        return t('status.title.success')

      case 'warning':
        return t('status.title.warning')

      default:
        return 'DrivstoffAppen'
    }
  })

  onMounted(() => {
    if (props.toast.timeout) {
      setTimeout(close, props.toast.timeout)
    }
  })
</script>

<template>
  <div
    class="w-full rounded-md border bg-white/90 p-3 shadow-lg backdrop-blur transition-colors dark:bg-dark-700/90"
    :class="{
      'border-dark-400': toast.type === 'default',
      'border-primary-500': toast.type === 'primary',
      'border-danger-500': toast.type === 'error',
      'border-info-500': toast.type === 'info',
      'border-success-500': toast.type === 'success',
      'border-warning-500': toast.type === 'warning',
    }"
  >
    <div
      class="flex items-center border-b border-dark-200/60 pb-2 dark:border-dark-600/60"
    >
      <div
        class="grow text-sm font-medium"
        :class="{
          'text-dark-500': toast.type === 'default',
          'text-primary-500': toast.type === 'primary',
          'text-danger-500': toast.type === 'error',
          'text-info-500': toast.type === 'info',
          'text-success-500': toast.type === 'success',
          'text-warning-500': toast.type === 'warning',
        }"
      >
        {{ title }}
      </div>

      <SvgImage
        symbol="icon-x-mark"
        class="absolute right-0 top-1 flex size-8 cursor-pointer items-center justify-center p-2 text-dark-400 transition-all hover:text-dark-700 dark:text-dark-300 dark:hover:text-dark-200"
        @click="close"
      />
    </div>

    <div class="mx-0 mb-1 mt-3 text-sm font-normal">
      <div>{{ toast.content }}</div>

      <div
        v-if="toast.link"
        class="mt-1"
      >
        <NuxtLink :to="toast.link.to">{{ toast.link.text }}</NuxtLink>
      </div>
    </div>
  </div>
</template>
