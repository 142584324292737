<script setup lang="ts">
  const props = defineProps<{
    code: string
    square?: boolean
  }>()

  const symbol = computed<string>(() => {
    let flagCode: string = props.code

    switch (props.code) {
      case 'nb':
        flagCode = 'no'
        break
    }

    const suffix = props.square ? '-square' : ''

    return `flag-icon-${flagCode}${suffix}`
  })
</script>

<template>
  <SvgImage :symbol="symbol" />
</template>
