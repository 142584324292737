import { Notify } from './types/notify'
import NotifyList from './components/NotifyList.vue'

export default defineNuxtPlugin((nuxtApp) => {
  const notify = reactive(new Notify())

  nuxtApp.vueApp.component('NotifyList', NotifyList)

  return {
    provide: {
      notify,
    },
  }
})
