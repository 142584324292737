import type { NumberFormat } from '@intlify/core-base'
import type { LocaleObject } from '@nuxtjs/i18n'

export type TLocaleCode = 'gb' | 'nb'

export type TLocaleIso = 'en-GB' | 'nb-NO'

export interface ILocale extends LocaleObject {
  code: TLocaleCode
  language: TLocaleIso
  file: string
  name: string
}

export const AVAILABLE_LOCALES: ILocale[] = [
  {
    code: 'gb',
    language: 'en-GB',
    file: 'en-GB.json',
    name: 'English (GB)',
  },
  {
    code: 'nb',
    language: 'nb-NO',
    file: 'nb-NO.json',
    name: 'Norsk',
  },
]

export const NUMBER_FORMATS: Record<TLocaleIso, NumberFormat> = {
  'en-GB': {
    currency: {
      style: 'currency',
      currency: 'NOK',
      currencyDisplay: 'narrowSymbol',
    },
  },
  'nb-NO': {
    currency: {
      style: 'currency',
      currency: 'NOK',
      currencyDisplay: 'narrowSymbol',
    },
  },
}
