
// @ts-nocheck


export const localeCodes =  [
  "gb",
  "nb"
]

export const localeLoaders = {
  "gb": [{ key: "../../../../app/locales/en-GB.json", load: () => import("../../../../app/locales/en-GB.json" /* webpackChunkName: "locale__vercel_path0_app_locales_en_GB_json" */), cache: true }],
  "nb": [{ key: "../../../../app/locales/nb-NO.json", load: () => import("../../../../app/locales/nb-NO.json" /* webpackChunkName: "locale__vercel_path0_app_locales_nb_NO_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../../../../i18n.config.ts?hash=99279338&config=1" /* webpackChunkName: "i18n_config_99279338" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "./server/locale/localeDetector.ts",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "gb",
      "language": "en-GB",
      "name": "English (GB)",
      "files": [
        "/vercel/path0/app/locales/en-GB.json"
      ]
    },
    {
      "code": "nb",
      "language": "nb-NO",
      "name": "Norsk",
      "files": [
        "/vercel/path0/app/locales/nb-NO.json"
      ]
    }
  ],
  "defaultLocale": "nb",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "gb",
    "language": "en-GB",
    "name": "English (GB)",
    "files": [
      {
        "path": "/vercel/path0/app/locales/en-GB.json"
      }
    ]
  },
  {
    "code": "nb",
    "language": "nb-NO",
    "name": "Norsk",
    "files": [
      {
        "path": "/vercel/path0/app/locales/nb-NO.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
