export const NOTIFY_TOAST_TIMEOUT = 5000

export type TNotifyItemType =
  | 'default'
  | 'primary'
  | 'info'
  | 'error'
  | 'warning'
  | 'success'

export interface INotifyItem {
  type: TNotifyItemType
  content: string
  title?: string
  icon?: string
  timeout?: number | null
  dismissible?: boolean
  withSound?: boolean
  timestamp?: Date
  link?: {
    to: string
    text: string
  }
}

export interface INotify {
  queue: INotifyItem[]
  add: (options: INotifyItem) => void
  remove: (options: INotifyItem) => void
  reset: () => void
  show: (options: INotifyItem) => void
  showByType: (
    type: TNotifyItemType,
    content: string,
    title?: string,
    options?: Partial<INotifyItem>,
  ) => void
  showDefault: (
    content: string,
    title?: string,
    options?: Partial<INotifyItem>,
  ) => void
  showError: (
    content: string,
    title?: string,
    options?: Partial<INotifyItem>,
  ) => void
  showInfo: (
    content: string,
    title?: string,
    options?: Partial<INotifyItem>,
  ) => void
  showSuccess: (
    content: string,
    title?: string,
    options?: Partial<INotifyItem>,
  ) => void
  showWarning: (
    content: string,
    title?: string,
    options?: Partial<INotifyItem>,
  ) => void
}

export class Notify implements INotify {
  items: INotifyItem[] = []

  get queue() {
    return this.items
  }

  add(toast: INotifyItem) {
    toast.timestamp?.setMilliseconds(
      toast.timestamp?.getMilliseconds() + this.items.length,
    )

    this.items.unshift(toast)

    if (toast.withSound) {
      const audio = new Audio('/sounds/note.mp3')
      audio.volume = 0.5
      audio.play()
    }
  }

  remove(options: INotifyItem) {
    const indexToDelete = this.items.findIndex(
      (t) => t.timestamp === options.timestamp,
    )

    if (indexToDelete !== -1) {
      this.items.splice(indexToDelete, 1)
    }
  }

  show(options: INotifyItem): void {
    switch (options.type) {
      case 'error':
        options.icon ??= 'x-circle'
        break

      case 'warning':
        options.icon ??= 'exclamation-circle'
        break

      case 'success':
        options.icon ??= 'check-circle'
        break

      case 'info':
        options.icon ??= 'info-circle'
        break

      case 'default':
        options.icon ??= 'info-circle'
        break
    }

    this.add({
      timeout:
        options.timeout !== undefined ? options.timeout : NOTIFY_TOAST_TIMEOUT,
      timestamp: new Date(),
      ...options,
    })
  }

  reset(): void {
    this.items = []
  }

  showByType(
    type: TNotifyItemType,
    content: string,
    title?: string,
    options?: Partial<INotifyItem>,
  ): void {
    this.show({
      ...options,
      type,
      content,
      title: title ?? options?.title,
    })
  }

  showDefault(
    content: string,
    title?: string,
    options?: Partial<INotifyItem>,
  ): void {
    this.showByType('default', content, title, options)
  }

  showInfo(
    content: string,
    title?: string,
    options?: Partial<INotifyItem>,
  ): void {
    this.showByType('info', content, title, options)
  }

  showError(
    content: string,
    title?: string,
    options?: Partial<INotifyItem>,
  ): void {
    this.showByType('error', content, title, options)
  }

  showSuccess(
    content: string,
    title?: string,
    options?: Partial<INotifyItem>,
  ): void {
    this.showByType('success', content, title, options)
  }

  showWarning(
    content: string,
    title?: string,
    options?: Partial<INotifyItem>,
  ): void {
    this.showByType('warning', content, title, options)
  }
}
