<script setup lang="ts">
  import type { NuxtError } from 'nuxt/app'
  import type { IAppErrorData } from '~~/types/error'

  defineProps<{
    error: NuxtError<IAppErrorData>
  }>()

  const route = useRoute()
  const isProduction = process.env.NODE_ENV === 'production'

  const isViewPage = computed<boolean>(() =>
    (route.name as string)?.startsWith('view'),
  )

  const handleError = () => {
    clearError({ redirect: '/' })
  }
</script>

<template>
  <div class="flex min-h-screen flex-col dark:bg-dark-800 dark:text-white">
    <div
      class="container relative z-10 mx-auto flex min-h-screen max-w-screen-lg grow flex-col"
    >
      <header>
        <nav
          class="flex items-center justify-end px-3 py-5 sm:px-5 lg:px-16 xl:px-20"
        >
          <LocaleSwitchSimple class="flex items-center gap-5" />
        </nav>
      </header>

      <main class="my-auto py-40">
        <div>
          <div class="mb-16">
            <LogoFull class="mx-auto h-20 w-auto shrink-0" />
          </div>

          <div class="text-center text-7xl font-bold text-primary-500">
            {{ error.statusCode }}
          </div>

          <div class="my-10 text-center text-lg">
            {{ error.data?.message || $t('error.message.general-try-again') }}
          </div>

          <div
            v-if="!isViewPage"
            class="my-20 text-center"
          >
            <button
              class="rounded-md border border-primary-500 bg-transparent px-10 py-2.5 text-sm font-semibold transition hover:bg-primary-500 hover:text-white"
              @click="handleError()"
            >
              {{ error.data?.redirect?.label ?? $t('error.label.navigate') }}
            </button>
          </div>
        </div>

        <div
          v-if="!isProduction"
          class="border-t-2 border-danger-500 bg-dark-50 dark:bg-dark-900"
        >
          <div
            class="container mx-auto my-auto max-w-screen-md px-4 py-10 sm:px-10"
          >
            <ErrorDebugInfo :error="error" />
          </div>
        </div>
      </main>
    </div>

    <SvgSpriteIndex class="hidden" />

    <SvgSpriteFlags style="display: none" />
  </div>
</template>
