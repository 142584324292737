<script setup lang="ts">
  import type { TLocaleCode } from '~~/types/locale'
  import { AVAILABLE_LOCALES } from '~~/types/locale'

  const { locale, setLocale, setLocaleCookie } = useI18n()

  const localeChange = (value: TLocaleCode): void => {
    setLocale(value)
    setLocaleCookie(value)
  }
</script>

<template>
  <div>
    <label
      v-for="localeItem in AVAILABLE_LOCALES"
      :key="localeItem.code"
      :for="`settings-locale-${localeItem.code}`"
      class="item-center flex cursor-pointer gap-2"
      :title="localeItem.name"
    >
      <input
        :id="`settings-locale-${localeItem.code}`"
        type="radio"
        :value="localeItem.code"
        :checked="locale === localeItem.code"
        class="peer hidden"
        @change="localeChange(localeItem.code)"
      />

      <SvgFlagByLocale
        :code="localeItem.code"
        :square="true"
        class="size-6 rounded-full ring-2"
        :class="{
          'ring-dark-100/80': locale !== localeItem.code,
          'ring-primary-500': locale === localeItem.code,
        }"
      />
    </label>
  </div>
</template>
