import { useAuthStore } from '~~/stores/auth'

export default defineNuxtRouteMiddleware((to) => {
  const config = useRuntimeConfig()

  const { isAuthenticated } = storeToRefs(useAuthStore())

  if (config.public.app.maintenance.enabled) {
    if (to.path !== '/maintenance') {
      return navigateTo('/maintenance')
    }
  } else if (to.path === '/maintenance') {
    return navigateTo(isAuthenticated.value ? '/' : '/auth/sign-in')
  }
})
