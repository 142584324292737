import { Buffer } from 'buffer'
import { consola } from 'consola'
import { acceptHMRUpdate, defineStore } from 'pinia'
import type {
  IAccount,
  TAccountRoleType,
  TAccountSignUp,
} from '~~/types/account'
import {
  ACCOUNT_SETTING_RELEASE_NOTES,
  AccountRoleType,
  AccountStatus,
} from '~~/types/account'
import { $request } from '~/utils/api'

type TAuthStoreStatus = undefined | 'pending' | 'ready'

export const useAuthStore = defineStore('auth', () => {
  const session = ref<{
    account: IAccount | undefined
    token: string | undefined
  }>({
    account: undefined,
    token: undefined,
  })

  const status = ref<TAuthStoreStatus>(undefined)

  const account = computed<IAccount | undefined>(() => session.value.account)
  const accountName = computed<string | undefined>(() =>
    account.value && account.value.firstName
      ? `${account.value.firstName} ${account.value.lastName}`
      : undefined,
  )
  const accountInitials = computed<string | undefined>(() =>
    account.value
      ? account.value.firstName?.[0]
        ? account.value.lastName?.[0]
          ? account.value.firstName[0] + account.value.lastName[0]
          : account.value.firstName[0]
        : undefined
      : undefined,
  )
  const isAdmin = computed<boolean>(() => hasRole(AccountRoleType.ADMIN))
  const isCustomer = computed<boolean>(() => hasRole(AccountRoleType.CUSTOMER))
  const hasRole = (role: TAccountRoleType): boolean => {
    return !!account.value?.roles?.includes(role)
  }
  const hasOneOfRoles = (roles: TAccountRoleType[]): boolean => {
    return !!account.value?.roles?.some((type) => roles.includes(type))
  }
  const isAuthenticated = computed<boolean>(
    () => !!session.value.token && !!account.value,
  )
  const isVerified = computed<boolean>(
    () => !!account.value && account.value.status === AccountStatus.ACTIVE,
  )
  const isReady = computed<boolean>(
    () => isAuthenticated.value && isVerified.value && status.value === 'ready',
  )

  /**
   * Initialize session.
   */
  const init = async (): Promise<void> => {
    try {
      status.value = 'pending'
      await getAccessToken()

      if (session.value.token) {
        await accountFetch()
      }
    } catch (e) {
      if (import.meta.dev) {
        consola.error(e)
      }
    } finally {
      status.value = 'ready'
    }
  }
  /**
   * Clear session.
   */
  const clear = (): void => {
    session.value.token = undefined
    session.value.account = undefined
    status.value = undefined
  }

  /**
   * Validate current session.
   *
   * @returns {boolean}
   */
  const sessionValidate = (): boolean => {
    // Check if access token is about to expire.
    if (session.value.token) {
      // Parse access token data and verify its validity.
      const tokenParts = session.value.token.split('.')

      if (!tokenParts[1]) {
        return false
      }

      const accessTokenData = JSON.parse(
        Buffer.from(tokenParts[1], 'base64').toString(),
      )
      const accessTokenExpires =
        ('exp' in accessTokenData && Number(accessTokenData.exp)) || undefined

      if (accessTokenExpires) {
        const expiresDate = new Date(accessTokenExpires * 1000)
        const dateDiffFromNow = new Date()
        dateDiffFromNow.setSeconds(dateDiffFromNow.getSeconds() + 10)

        return expiresDate > dateDiffFromNow
      }
    }

    return false
  }

  /**
   * Validate and return access token if exists or try to obtain a new one.
   *
   * @returns {Promise<string|undefined>}
   */
  const getAccessToken = async (): Promise<string | undefined> => {
    try {
      const isSessionValid = sessionValidate()

      // If current access token is expired or not exists, try to send refresh
      // request to obtain the new access token.
      if (!isSessionValid) {
        // Refresh token should be set as Http-only cookie by the server.
        const { accessToken } = await $fetch<{
          accessToken: string
        }>('/api/auth/refresh', { method: 'POST' })

        session.value.token = accessToken
      }

      return session.value.token
    } catch (e) {
      await clear()

      if (import.meta.dev) {
        consola.error(e)
      }

      return undefined
    }
  }

  /**
   * Fetch account data.
   */
  const accountFetch = async (): Promise<void> => {
    try {
      status.value = 'pending'

      const { account: accountData } = await $request<{
        account: IAccount
      }>('/api/account/profile', {
        method: 'GET',
      })

      session.value.account = accountData
    } catch (e) {
      await clear()

      return Promise.reject(e)
    } finally {
      status.value = 'ready'
    }
  }
  /**
   * Update account with data provided.
   *
   * @param {Partial<IAccount>} options Account data to update.
   * @returns
   */
  const accountChange = async (data: Partial<IAccount>): Promise<void> => {
    try {
      if (account.value) {
        status.value = 'pending'

        const { account: accountData } = await $request<{
          account: IAccount
        }>('/api/account/profile', {
          method: 'PATCH',
          body: data,
        })

        session.value.account = {
          ...(accountData ?? account.value),
          email: account.value.email,
          firstName: account.value.firstName,
          lastName: account.value.lastName,
        }
      }

      status.value = 'ready'
    } catch (e) {
      await clear()

      return Promise.reject(e)
    }
  }
  /**
   * Attempt to sign in a user with credentials.
   *
   * @param {string} email
   * @param {string} password
   */
  const signIn = async (email: string, password: string): Promise<void> => {
    try {
      status.value = 'pending'

      const { account: accountData, accessToken } = await $fetch<{
        account: IAccount
        accessToken: string
      }>('/api/auth/sign-in', {
        method: 'POST',
        body: { email, password },
        credentials: 'include',
      })

      session.value.token = accessToken
      session.value.account = accountData
      status.value = 'ready'
    } catch (e) {
      await clear()

      return Promise.reject(e)
    }
  }
  /**
   * Attempt to sign up a new account by invitation.
   *
   * @param {TAccountSignUp} options
   * @param {string} uuid
   */
  const signUpInvite = async (
    signData: TAccountSignUp,
    invitationId: string,
  ): Promise<void> => {
    try {
      status.value = 'pending'

      const { account: accountData, accessToken } = await $fetch<{
        account: IAccount
        accessToken: string
      }>('/api/auth/sign-up', {
        method: 'POST',
        body: {
          id: invitationId,
          ...signData,
        },
        credentials: 'include',
      })

      session.value.token = accessToken
      session.value.account = accountData
      status.value = 'ready'
    } catch (e) {
      clear()

      return Promise.reject(e)
    }
  }
  /**
   * Attempt to sign out current user.
   */
  const signOut = async (): Promise<void> => {
    try {
      status.value = 'pending'

      await $request('/api/auth/sign-out', {
        method: 'POST',
        credentials: 'include',
      })
    } catch (e) {
      return Promise.reject(e)
    } finally {
      clear()
    }
  }
  /**
   * Attempt to recover user access.
   *
   * @param {string} email
   */
  const signRecover = async (email: string): Promise<void> => {
    try {
      status.value = 'pending'

      await $fetch('/api/auth/sign-recover', {
        method: 'POST',
        body: { email },
        credentials: 'include',
      })
    } catch (e) {
      return Promise.reject(e)
    } finally {
      status.value = 'ready'
    }
  }
  /**
   * Attempt to reset password.
   *
   * @param {string} id
   * @param {string} password
   */
  const signPasswordReset = async (
    id: string,
    password: string,
  ): Promise<void> => {
    try {
      status.value = 'pending'

      await $fetch('/api/auth/sign-password-reset', {
        method: 'POST',
        body: {
          id,
          password,
        },
        credentials: 'include',
      })
    } catch (e) {
      return Promise.reject(e)
    } finally {
      status.value = 'ready'
    }
  }
  /**
   * Attempt to verify a user by OTP.
   *
   * @param {string} otp - OTP to verify account.
   * @returns
   */
  const signVerify = async (otp: string): Promise<void> => {
    try {
      status.value = 'pending'

      const { account: accountData } = await $request<{
        account: IAccount
      }>('/api/auth/sign-verify', {
        method: 'POST',
        body: { otp },
        credentials: 'include',
      })

      session.value.account = accountData
    } catch (e) {
      return Promise.reject(e)
    } finally {
      status.value = 'ready'
    }
  }

  const releaseNotesVersionChecked = computed<string | undefined>(
    () =>
      account.value?.settings?.find(
        (item) => item.key === ACCOUNT_SETTING_RELEASE_NOTES,
      )?.value,
  )
  /**
   * Update account setting - release notes viewed.
   */
  const releaseNotesVersionCheck = async (version: string): Promise<void> => {
    try {
      if (!isAuthenticated.value) {
        return
      }

      await $request('/api/settings/releases', {
        method: 'POST',
        body: {
          version,
        },
      })
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return {
    init,
    clear,

    account,
    accountName,
    accountInitials,
    isAdmin,
    isCustomer,
    isAuthenticated,
    isVerified,
    isReady,

    getAccessToken,
    hasRole,
    hasOneOfRoles,
    signUpInvite,
    signIn,
    signOut,
    signPasswordReset,
    signRecover,
    signVerify,
    accountChange,

    releaseNotesVersionChecked,
    releaseNotesVersionCheck,
  }
})

import.meta.hot?.accept(acceptHMRUpdate(useAuthStore, import.meta.hot))
