<script setup lang="ts">
  import NotifyItem from './NotifyItem.vue'

  const { $notify } = useNuxtApp()
</script>

<template>
  <div class="fixed max-h-screen">
    <div class="relative grid max-h-screen gap-2 pb-4 empty:pb-0">
      <TransitionGroup name="notify-list">
        <NotifyItem
          v-for="(toast, toastIndex) in $notify?.queue || []"
          :key="toastIndex"
          :toast="toast"
        />
      </TransitionGroup>
    </div>

    <button
      v-if="$notify?.queue.length > 1"
      class="absolute -left-9 top-0 z-10 size-8 cursor-pointer rounded-md bg-white p-2 text-sm text-dark-500 shadow-md transition-all hover:bg-dark-100"
      @click="$notify?.reset()"
    >
      <SvgImage symbol="icon-x-mark" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
  .notify-list {
    &-move,
    &-enter-active,
    &-leave-active {
      transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: translateY(30px, 0);
    }

    &-leave-active {
      position: absolute;
    }
  }
</style>
