<script setup lang="ts">
  const nuxtApp = useNuxtApp()
  const { locale } = useI18n()

  nuxtApp.hook('page:start', () => {
    document.body.style.overflow = 'initial'
  })
</script>

<template>
  <Html :lang="locale">
    <Body
      class="prose-headings:font-display prose prose-neutral relative min-h-screen max-w-none bg-white dark:prose-invert prose-headings:font-medium dark:bg-dark-800"
    >
      <div id="app">
        <NuxtLoadingIndicator
          color="repeating-linear-gradient(to right,#fc3b17 0%,#f79512 100%)"
        />

        <NuxtLayout>
          <NuxtPage />
        </NuxtLayout>
      </div>

      <SvgSpriteIndex style="display: none" />

      <SvgSpriteFlags style="display: none" />
    </Body>
  </Html>
</template>
