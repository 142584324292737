<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style="display: none"
  >
    <symbol
      id="flag-icon-dk"
      viewBox="0 0 640 480"
    >
      <path
        fill="#c8102e"
        d="M0 0h640.1v480H0z"
      />
      <path
        fill="#fff"
        d="M205.7 0h68.6v480h-68.6z"
      />
      <path
        fill="#fff"
        d="M0 205.7h640.1v68.6H0z"
      />
    </symbol>

    <symbol
      id="flag-icon-dk-square"
      viewBox="0 0 512 512"
    >
      <path
        fill="#c8102e"
        d="M0 0h512.1v512H0z"
      />
      <path
        fill="#fff"
        d="M144 0h73.1v512H144z"
      />
      <path
        fill="#fff"
        d="M0 219.4h512.1v73.2H0z"
      />
    </symbol>

    <symbol
      id="flag-icon-fi"
      viewBox="0 0 640 480"
    >
      <path
        fill="#fff"
        d="M0 0h640v480H0z"
      />
      <path
        fill="#002f6c"
        d="M0 174.5h640v131H0z"
      />
      <path
        fill="#002f6c"
        d="M175.5 0h130.9v480h-131z"
      />
    </symbol>

    <symbol
      id="flag-icon-fi-square"
      viewBox="0 0 512 512"
    >
      <path
        fill="#fff"
        d="M0 0h512v512H0z"
      />
      <path
        fill="#002f6c"
        d="M0 186.2h512v139.6H0z"
      />
      <path
        fill="#002f6c"
        d="M123.2 0h139.6v512H123.1z"
      />
    </symbol>

    <symbol
      id="flag-icon-gb"
      viewBox="0 0 640 480"
    >
      <path
        fill="#012169"
        d="M0 0h640v480H0z"
      />
      <path
        fill="#ffffff"
        d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"
      />
      <path
        fill="#c8102e"
        d="m424 281 216 159v40L369 281h55zm-184 20 6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"
      />
      <path
        fill="#ffffff"
        d="M241 0v480h160V0H241zM0 160v160h640V160H0z"
      />
      <path
        fill="#c8102e"
        d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"
      />
    </symbol>

    <symbol
      id="flag-icon-gb-square"
      viewBox="0 0 512 512"
    >
      <path
        fill="#012169"
        d="M0 0h512v512H0z"
      />
      <path
        fill="#fffffff"
        d="M512 0v64L322 256l190 187v69h-67L254 324 68 512H0v-68l186-187L0 74V0h62l192 188L440 0z"
      />
      <path
        fill="#c8102e"
        d="m184 324 11 34L42 512H0v-3l184-185zm124-12 54 8 150 147v45L308 312zM512 0 320 196l-4-44L466 0h46zM0 1l193 189-59-8L0 49V1z"
      />
      <path
        fill="#ffffff"
        d="M176 0v512h160V0H176zM0 176v160h512V176H0z"
      />
      <path
        fill="#c8102e"
        d="M0 208v96h512v-96H0zM208 0v512h96V0h-96z"
      />
    </symbol>

    <symbol
      id="flag-icon-no"
      viewBox="0 0 640 480"
    >
      <path
        fill="#ed2939"
        d="M0 0h640v480H0z"
      />
      <path
        fill="#ffffff"
        d="M180 0h120v480H180z"
      />
      <path
        fill="#ffffff"
        d="M0 180h640v120H0z"
      />
      <path
        fill="#002664"
        d="M210 0h60v480h-60z"
      />
      <path
        fill="#002664"
        d="M0 210h640v60H0z"
      />
    </symbol>

    <symbol
      id="flag-icon-no-square"
      viewBox="0 0 512 512"
    >
      <path
        fill="#ed2939"
        d="M0 0h512v512H0z"
      />
      <path
        fill="#ffffff"
        d="M128 0h128v512H128z"
      />
      <path
        fill="#ffffff"
        d="M0 192h512v128H0z"
      />
      <path
        fill="#002664"
        d="M160 0h64v512h-64z"
      />
      <path
        fill="#002664"
        d="M0 224h512v64H0z"
      />
    </symbol>

    <symbol
      id="flag-icon-se"
      viewBox="0 0 640 480"
    >
      <path
        fill="#005293"
        d="M0 0h640v480H0z"
      />
      <path
        fill="#fecb00"
        d="M176 0v192H0v96h176v192h96V288h368v-96H272V0h-96z"
      />
    </symbol>

    <symbol
      id="flag-icon-se-square"
      viewBox="0 0 512 512"
    >
      <path
        fill="#005293"
        d="M0 0h512v512H0z"
      />
      <path
        fill="#fecb00"
        d="M134 0v204.8H0v102.4h134V512h102.4V307.2H512V204.8H236.4V0H134z"
      />
    </symbol>
  </svg>
</template>
