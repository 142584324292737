import type { NitroFetchOptions, NitroFetchRequest } from 'nitropack'
import { useAuthStore } from '~~/stores/auth'

export const $request = <T>(
  path: string,
  options?: NitroFetchOptions<
    NitroFetchRequest,
    | 'get'
    | 'head'
    | 'patch'
    | 'post'
    | 'put'
    | 'delete'
    | 'connect'
    | 'options'
    | 'trace'
  >,
) => {
  return $fetch<T>(path, {
    ...options,
    async onRequest({ options }) {
      const accessToken = await useAuthStore().getAccessToken()

      if (accessToken) {
        const headers = new Headers(options.headers)

        headers.set('Accept', 'application/json')
        headers.set('Authorization', `Bearer ${accessToken}`)

        options.headers = headers
      }
    },
    async onResponseError({ response }) {
      if (response.status === 401) {
        await useAuthStore().clear()
      }
    },
  })
}
