import type { PrismaHub } from '~~/types/db'
import type { ICustomer } from '~~/types/customer'
import type { IRecord } from '~~/types/record'

export const CUSTOMER_SYSTEM = 'DRIVSTOFFAPPEN'
export const PASSWORD_SYMBOLS = ['~', '@', '#', '$', '*', '_', '-']
export const ACCOUNT_SETTING_RELEASE_NOTES = 'release_notes_viewed'

export enum AccountRecoveryStatus {
  PENDING = 'pending',
  ERROR = 'error',
  COMPLETED = 'completed',
}

export enum AccountInvitationStatus {
  PENDING = 'pending',
  ERROR = 'error',
  COMPLETED = 'completed',
}

export enum AccountStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  DISABLED = 'disabled',
}

export enum AccountRoleType {
  ADMIN = 'admin',
  CUSTOMER = 'customer',
}

export type TAccountStatusType = AccountStatus[keyof AccountStatus]

export type TAccountRoleType = AccountRoleType[keyof AccountRoleType]

export type TAccount = PrismaHub.AccountGetPayload<{
  include: {
    roles: true
    settings: true
    customer: {
      include: {
        _count: {
          select: {
            stations: true
          }
        }
      }
    }
  }
}>

export type TAccountRole = PrismaHub.AccountRoleGetPayload<undefined>

export interface IAccountRole extends IRecord, Pick<TAccountRole, 'type'> {}

export interface IAccountRelationsIncludeDefault
  extends Pick<PrismaHub.AccountInclude, 'roles' | 'customer'> {
  roles: true
  settings: true
  customer: {
    where?: PrismaHub.CustomerWhereInput
    include: {
      _count: {
        select: {
          stations: true
        }
      }
    }
  }
}

export const AccountRelationsIncludeDefault: IAccountRelationsIncludeDefault = {
  roles: true,
  settings: true,
  customer: {
    where: {
      system: CUSTOMER_SYSTEM,
    },
    include: {
      _count: {
        select: {
          stations: true,
        },
      },
    },
  },
}

export interface IAccount
  extends Pick<
      TAccount,
      'id' | 'email' | 'firstName' | 'lastName' | 'pictureUrl'
    >,
    Partial<Pick<TAccount, 'status' | 'settings'>> {
  roles: TAccountRoleType[]
  customer?: Omit<ICustomer, 'account'> | null
  visitAt?: Date
}

export type TAccountInvitation = PrismaHub.AccountInvitationGetPayload<{
  include: {
    account: {
      include: {
        customer: {
          include: {
            _count: {
              select: {
                stations: true
              }
            }
          }
        }
        roles: true
      }
    }
  }
}>

export interface IAccountInvitation
  extends IRecord,
    Omit<TAccountInvitation, 'account'> {
  account?: IAccount
}

export type TAccountSignUp = Partial<PrismaHub.AccountGetPayload<undefined>>

export type IAccountPasswordReset = {
  passwordNew: string
  passwordConfirm: string
}

export interface IAccountProfileForm {
  email: string
  firstName?: string
  lastName?: string
  password?: string
  passwordConfirm?: string
}

export type IAccountSignUp = Pick<
  PrismaHub.AccountCreateInput,
  'email' | 'password' | 'firstName' | 'lastName'
> & { passwordConfirm: string }
